<template>
    <div class="relate-wrapper">
        <navbar></navbar>
        <div class="relate-body">
            <div class="relate-content">
                <div class="title">联系我们</div>
                <div class="info-content">
                    <div v-for="(item,index) in list" :key="index" class="info-block">
                        <img :src="item.imgSrc" class="info-img"/>
                        <div class="info-title">{{item.title}}</div>
                        <div>{{item.content}}</div>
                    </div>
                </div>
                <div class="title">给我们留言</div>
                <div class="form-content">
                    <el-form :model="form" class="form">
                        <el-form-item>
                            <el-input v-model="form.name" class="inps" placeholder="您的名字" maxlength="10"></el-input>
                            <el-input v-model="form.phoneNumber" class="inps" placeholder="联系方式" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input type="textarea" :rows="3" v-model="form.content" class="inp" placeholder="您的意见" maxlength="200"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="btn" @click="handleSubmit">发送</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <record color="#999999"></record>
        </div>
    </div>
</template>

<script>
    import {saveMessage} from '@/api/requestApi.js'

    import navbar from "./navbar";
    import record from "./record";
    export default {
        name: "relate",
        components:{
            navbar,
            record
        },
        data(){
            return {
                list:[
                    {
                        imgSrc: require('../assets/pc/relate/address.png'),
                        title:'公司地址',
                        content:'广州市天河区大观南路36号'
                    },
                    {
                        imgSrc: require('../assets/pc/relate/email.png'),
                        title:'电子邮箱',
                        content:'admin@ujtao.com'
                    },
                    {
                        imgSrc: require('../assets/pc/relate/qq.png'),
                        title:'QQ',
                        content:'2448584757'
                    }
                ],
                form: {
                    channel: 1, //留言渠道：1官网 2安卓 3IOS
                    name: '',
                    phoneNumber: '',
                    content: ''
                }
            }
        },
        methods:{
            handleSubmit(){
                if(!this.form.name || !this.form.phoneNumber || !this.form.content){
                    this.$message('请填写完整信息');
                    return false;
                }

                saveMessage(this.form).then(() => {
                    this.$message('感谢您的留言');
                    this.form = {};
                })
            }
        }
    }
</script>

<style>
    .el-input__inner{
        height: 46px !important;
    }
    .el-message{
        min-width: auto;
        background-color: rgba(0,0,0,.7);
        border: none;
        padding: 15px 20px;
        border-radius: 10px;
        top: 50% !important;
    }
    .el-message__icon{
        display: none;
    }
    .el-message--info .el-message__content{
        color: #ffffff;
        font-size: 14px;
    }
</style>
<style scoped lang="scss">
    @mixin width-height($width: 100%, $height: 100%){
        width: $width;
        height: $height;
    }
    @mixin font($size: 32px,$color:#222222,$weight:500){
        font-size: $size;
        color: $color;
        font-weight: $weight;
    }
    .relate-wrapper{
        @include width-height;
        display: flex;
        flex-direction: column;

        .relate-body{
            flex: 1;
            background-image: url('../assets/pc/relate/bg.png');
            background-size:100% auto;
            background-repeat: no-repeat;
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .relate-content{
                background: #ffffff;
                border-radius: 10px;
                width: 50%;
                box-shadow: 0px 14px 9px 0px rgba(234,234,234,0.78);
                margin-bottom: 35px;
                padding: 30px 50px 20px;
                position: absolute;

                .title{
                    @include font;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .info-content{
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 48px;

                    .info-block{
                        width: calc(100% /3);
                        @include font(16px,#666666,400);
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .info-img{
                            @include width-height(134px,134px);
                            margin-bottom: 20px;
                        }
                        .info-title{
                            @include font(20px,#222222,500);
                            margin-bottom: 15px;
                        }
                    }
                }
                .form-content{
                    display: flex;
                    justify-content: center;

                    .form{
                        width: 100%;

                        .inps{
                            width: calc((100% - 25px)/2);
                            height: 46px;
                            &:first-child{
                                margin-right: 25px;
                            }
                        }
                        .btn{
                            padding: 10px 43px;
                            background: #fa0681;
                            @include font(16px,#fff,400);
                        }
                    }
                }
            }
        }
    }
</style>
